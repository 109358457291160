import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { postData } from "../../api/apiDataExchange";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";

const SavingEnergyPopup = ({
  setOpenGreenSavingsPopup,
  openGreenSavingsPopup,
  savingsThroughGreenMeterList,
  selectedRecommendationState,
  selectedRecommedationYear,
  filters,
  saveEnergyRecommendationType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState([]);

  const navigate = useNavigate();

  // Fetch data for a specific consumer
  const fetchTabData = async (consumerIndex) => {
    try {
      setLoading(true);
      const meterIdList = savingsThroughGreenMeterList.map(
        (meter) => meter._id
      );
      const selectedConsumer = savingsThroughGreenMeterList[consumerIndex];
      const filterResult = getFinancialYearDates(selectedRecommedationYear);

      const data = {
        meterId: meterIdList,
        organisationId: filters.organisationId,
        stateCode: selectedRecommendationState,
        startDate: filterResult.startDate,
        endDate: filterResult.endDate,
      };

      const saveGreenEnergyData = await postData(
        `${
          process.env.REACT_APP_API_URL
        }/recommendation/saveEnergyDashboardStats?pricetype=${
          saveEnergyRecommendationType || "constantprice"
        }&&calculations_steps=0`,
        data
      );

      const recData = { ...saveGreenEnergyData?.data?.data };

      setTabData((prevData) => {
        const updatedData = [...prevData];
        updatedData[consumerIndex] = {
          ...selectedConsumer,
          ...recData,
        };
        return updatedData;
      });
    } catch (error) {
      console.error("Error fetching tab data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch initial data for the default active tab
  useEffect(() => {
    if (openGreenSavingsPopup) {
      setIsOpen(true);
      fetchTabData(activeTab);
      console.log({ tabData });
    }
  }, [openGreenSavingsPopup]);

  // Handle tab changes
  const handleTabChange = (index) => {
    setActiveTab(index);
    if (!tabData[index]) {
      fetchTabData(index);
    }
  };

  // Utility: Get financial year dates
  const getFinancialYearDates = (financialYear) => {
    const yearParts = financialYear.slice(2).split("-");
    const startYear = parseInt(yearParts[0]);
    const endYear = parseInt(yearParts[1]) + 2000;

    const startDate = `04/${startYear}`;
    const endDate = `03/${endYear}`;
    return { startDate, endDate };
  };

  const buildURL = () => {
    const dates = getFinancialYearDates(selectedRecommedationYear);
    let url = `/recommendation-list?org=${filters.organisationId}&startDate=${dates.startDate}&endDate=${dates.endDate}`;

    if (filters.stateCode) {
      url += `&state=${selectedRecommendationState}`;
    }

    if (filters.meterId && filters.meterId.length > 0) {
      url += `&consumers=[${filters.meterId}]`;
    }

    return url;
  };

  const url = buildURL();

  const handleRecommendationOpen = () => {
    navigate(`${url}`, {
      state: { tabData },
    });
  };

  useEffect(() => {
    console.log({ tabData });
  }, [tabData]);

  return (
    <div className="">
      {isOpen && (
        <div
          className="fixed z-50 inset-0 flex items-center justify-center rounded-xl"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-center justify-center text-center ">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity"
              aria-hidden="true"
            ></div>
            <div className="absolute align-bottom rounded-lg text-left mt-20">
              <div
                className="bg-white rounded-lg shadow-lg"
                style={{ width: "40rem", height: "33rem" }}
              >
                <div className="p-8">
                  <div className="mb-2 ml-1">
                    Financial Year : {selectedRecommedationYear}
                  </div>
                  <div className="mb-2 ml-1">State : {"Maharashtra"}</div>

                  <div
                    className="absolute top-1 right-0 cursor-pointer"
                    onClick={() => {
                      setIsOpen(false);
                      setOpenGreenSavingsPopup(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faClose}
                      className="text-[#000000] mr-2 p-4 text-xl font-medium transition-all ease-out"
                    />
                  </div>

                  <div className="mt-4">
                    <div className="border-b py-0.5 border-gray-200">
                      {/* <nav className="-mb-px flex space-x-2" aria-label="Tabs">
                        {savingsThroughGreenMeterList.map((savings, index) => (
                          <div
                            key={index}
                            className={`px-4 py-2 border-b-2 font-medium min-w-[300px]  cursor-pointer max-w-[300px] text-sm rounded-t-md shadow ${
                              activeTab === index
                                ? "border-b-indigo-500 bg-white  text-indigo-600 shadow-md border rounded-t-xl"
                                : "border-transparent bg-gray-200 text-gray-500 hover:text-gray-700 hover:border-gray-300"
                            }`}
                            onClick={() => handleTabChange(index)}
                          >
                            <div className="">
                              Consumer No : {savings.consumerNo} <br />
                              <span className="text-[12px] uppercase">
                                {`${savings?.unitName} (${savings?.discom})`}
                              </span>
                            </div>
                          </div>
                        ))}
                      </nav> */}
                    </div>
                    {loading ? (
                      <div className="flex justify-center items-center mt-8">
                        <LoaderComponent width={"60"} height={"60"} />
                      </div>
                    ) : (
                      <div className="mt-4">
                        {tabData[activeTab] ? (
                          <div className="container mx-auto">
                            <table className=" w-full border-collapse border border-gray-300">
                              <tbody>
                                <tr>
                                  <td className="pl-4 w-[50%] py-2 bg-green-500 text-white">
                                    Number Of Consumers
                                  </td>
                                  <td className="pl-6  font-medium w-full py-2">
                                    {tabData[activeTab]["aggregatedTotals"][
                                      "consumer"
                                    ] || 0}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4 w-[50%] py-2 bg-green-500 text-white">
                                    Number Of Locations
                                  </td>
                                  <td className="pl-6  font-medium w-full py-2">
                                    {tabData[activeTab]["aggregatedTotals"][
                                      "consumerPresent"
                                    ] || 0}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4 w-[50%] py-2 bg-green-500 text-white">
                                    Total GC Consumers
                                  </td>
                                  <td className="pl-6  font-medium w-full py-2">
                                    {tabData[activeTab]["aggregatedTotals"][
                                      "gcConsumers"
                                    ] || 0}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 w-[50%]  bg-green-500 text-white">
                                    Total GC Savings
                                  </td>
                                  <td className="pl-6 py-2">
                                    <span className="flex flex-row items-center">
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faIndianRupeeSign}
                                      />
                                      {Number(
                                        tabData[activeTab]["aggregatedTotals"][
                                          "totalSavingsFromGroupCaptive"
                                        ]
                                      )?.toLocaleString("en-IN") || 0}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 w-[50%]  bg-green-500 text-white">
                                    Total Capacity GC
                                  </td>
                                  <td className="pl-6 flex py-2">
                                    <span className="flex flex-row items-center">
                                      {Number(
                                        tabData[activeTab]["aggregatedTotals"][
                                          "totalCapacityGC"
                                        ]
                                      )?.toLocaleString("en-IN") || 0}
                                    </span>
                                    &ensp;
                                    <span className="font-semibold">
                                      {" "}
                                      MW
                                    </span>{" "}
                                    &ensp;
                                  </td>
                                </tr>
                                <tr>
                                  <td className="pl-4 w-[50%] py-2 bg-green-500 text-white">
                                    Total RTS Consumers
                                  </td>
                                  <td className="pl-6  font-medium w-full py-2">
                                    {tabData[activeTab]["aggregatedTotals"][
                                      "rtsConsumers"
                                    ] || 0}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="px-4 py-2 w-[50%]  bg-green-500 text-white">
                                    Total RTS Savings
                                  </td>
                                  <td className="pl-6 py-2">
                                    <span className="flex flex-row items-center">
                                      <FontAwesomeIcon
                                        className="mr-1"
                                        icon={faIndianRupeeSign}
                                      />
                                      {Number(
                                        tabData[activeTab]["aggregatedTotals"][
                                          "totalSavingsFromRTS"
                                        ]
                                      )?.toLocaleString("en-IN") || 0}
                                    </span>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="px-4 py-2 w-[50%]  bg-green-500 text-white">
                                    Total Capacity RTS
                                  </td>
                                  <td className="pl-6 flex py-2">
                                    <span className="flex flex-row items-center">
                                      {Number(
                                        tabData[activeTab]["aggregatedTotals"][
                                          "totalCapacityRTS"
                                        ]
                                      )?.toLocaleString("en-IN") || 0}
                                    </span>
                                    &ensp;
                                    <span className="font-semibold"> MW</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="flex justify-end mt-5">
                              <button
                                className="px-4 py-2 bg-blue-500 text-white font-medium rounded hover:bg-blue-700 transition"
                                onClick={handleRecommendationOpen}
                              >
                                View More
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div>
                            No data available for the selected consumer.
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavingEnergyPopup;
